import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Hero from '../components/Hero'
import Feature from '../components/Feature'
import Testimonial from '../components/Testimonial'

import ecommerce from '../images/home_ecommerce.svg'
import iot from '../images/home_iot.svg'
import websites from '../images/home_websites.svg'
import erp from '../images/home_custom.svg'
import screenshot from '../images/home_screenshot.png'
import logoReact from '../images/tech_react.svg'
import logoNextjs from '../images/tech_nextjs.svg'
import logoGatsby from '../images/tech_gatsby.svg'
import logoGraphql from '../images/tech_graphql.svg'
import logoNodejs from '../images/tech_nodejs.svg'
// import testimonialGallini from '../images/testimonial_gallini.jpg'
// import testimonialGaldram from '../images/testimonial_galdram.svg'
import testimonialBrandolini from '../images/testimonial_brandolini.png'
import logoCisl from '../images/testimonial_cisl.svg'

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        description="F2.net is a JavaScript hi-tech development company. We create web applications using React and Node.js"
        keywords={[`F2.net`, `web applications`, `React`, `Gatsby`, `Next.js`]}
        title="React web applications development"
      />

      <Hero>
        <h1 className="text-3xl sm:text-5xl text-center font-black leading-tight mb-3">
          We develop beautiful web applications
        </h1>
        <p className="text-xl text-center leading-relaxed text-gray-700">
          We are a hi-tech web development company committed to deliver great
          products on time. We love to understand our customers' needs and
          exceed expectations.
        </p>
      </Hero>

      <section className="px-6">
        <div className="sm:flex flex-row flex-wrap max-w-3xl mx-auto pb-12 justify-between">
          <Feature
            imageUrl={ecommerce}
            // link="/portfolio"
            text="We create high performance custom Ecommerce websites for any industry and Booking systems."
            title="E-commerce systems"
          />
          <Feature
            imageUrl={iot}
            // link="/portfolio"
            text="IoT web applications talking with MQTT devices and Production management systems for Industry 4.0."
            title="IoT and Production management"
          />
          <Feature
            imageUrl={erp}
            // link="/portfolio"
            text="We can create custom CRMs and ERPs for any industry, like real estate, tourism, transport, municipalities."
            title="Custom CRM and ERPs"
          />
          <Feature
            imageUrl={websites}
            // link="/portfolio"
            text="We create blazing fast static websites using React, Gatsby and Next.js from multiple CMS data sources."
            title="Super fast websites"
          />
        </div>
      </section>

      <section className="bg-gray-200 py-12 sm:py-24">
        <div className="max-w-4xl mx-auto px-6 sm:flex sm:justify-between sm:space-x-18">
          <div className="flex-1 text-center sm:text-left">
            <h2 className="text-3xl font-extrabold mb-3">
              Our technology stack
            </h2>
            <p className="text-xl leading-relaxed text-gray-700">
              We love the Javascript opensource ecosystem and we are active in
              the React community, but we are not "technology hooligans": we
              know that technology is just a means to achieve our customer's
              goals.
            </p>
          </div>

          <div className="flex-1 flex flex-wrap justify-center">
            <div className="w-32 p-5">
              <img alt="React" className="mx-auto" src={logoReact} />
            </div>
            <div className="w-32 p-5">
              <img alt="GraphQL" className="mx-auto" src={logoGraphql} />
            </div>
            <div className="w-32 p-5">
              <img alt="Gatbsy" className="mx-auto" src={logoGatsby} />
            </div>
            <div className="w-32 p-5">
              <img alt="Nextjs" className="mx-auto" src={logoNextjs} />
            </div>
            <div className="w-32 p-5">
              <img alt="Nodejs" className="mx-auto" src={logoNodejs} />
            </div>
          </div>
        </div>
      </section>

      <section className="py-12 sm:py-24 px-6">
        <div className="max-w-4xl mx-auto px-4 sm:flex flex-wrap">
          <div className="flex-1 sm:pr-10">
            <img
              alt="Web Applications"
              className="block border border-gray-200 rounded-lg mx-auto mb-8 sm:mb-0 shadow-lg"
              src={screenshot}
            />
          </div>
          <div className="flex-1 md:pl-4 lg:pl-16 text-center sm:text-left">
            <h2 className="text-3xl font-extrabold mb-3">
              We built hundreds of apps
            </h2>
            <p className="text-xl leading-relaxed text-gray-700 mb-3">
              We create and host websites since 1997. We saw the Internet grow
              up as the standards evolved. We have built hundreds of successful
              web applications and we still have a lot of fun.
            </p>
            <p className="text-xl leading-relaxed text-gray-700 font-extrabold">
              You can count on us.
            </p>
          </div>
        </div>
      </section>

      <div className="max-w-4xl mx-auto px-4">
        <hr />
      </div>

      <section className="px-6 pt-12 pb-20">
        {/* <Testimonial
          authorJobTitle="CEO @ Galdram S.p.A."
          authorName="Massimiliano Gallini"
          avatarImage={testimonialGallini}
          logoImage={testimonialGaldram}
          quote={<span>F2.net is a skilled company which helped us define our <b>production management</b> application requirements and implemented it as a beautiful system that our users love. We are very satisfied.</span>}
        /> */}
        <Testimonial
          authorJobTitle="IT and DPO @ CISL Lombardia"
          authorName="Donato Brandolini"
          avatarImage={testimonialBrandolini}
          logoImage={logoCisl}
          quote={
            <span>
              We are happy to work with F2.net because they{' '}
              <b>understand our needs</b> and always deliver the product we had
              in mind. They use the <b>latest technologies</b> and put much care
              in <b>user experience</b> and data <b>security</b>, improving our
              productivity with tools our users love.
            </span>
          }
        />
      </section>
    </Layout>
  )
}

export default IndexPage
